import {Alert, Button, Form, Modal, Tabs} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {FDate, FInput, FMoney, FormGroup, FSelect, FSwitch} from '../../../components/form/FormInputs'
import UploaderAttachment from '../../Attendance/components/UploaderAttachment'
import {qs} from '../../queries'
import {TransactionModel} from '../../typings'

type TransactionDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof TransactionModel]?: TransactionModel[K] | '' }

const PAY_SELECT = [
  { value: 'BOLETO', label: 'Boleto' },
  { value: 'WIRE', label: 'Transferência' },
  { value: 'CC', label: 'Cartão de crédito' },
  { value: 'CHECK', label: 'Cheque' },
  { value: 'MONEY', label: 'Dinheiro' }
]

export const IncomeDetailPage: React.FC<TransactionDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getTransaction', item_id], () => qs.transactions.get(item_id), {
    enabled: false,
    retry: false
  })
  const patients = useQuery('getPatients', () => qs.patients.all())

  const create = useMutation(values => qs.transactions.post(values), {
    onSuccess: () => {
      handleCancel()
    }
  })
  const update = useMutation(values => qs.transactions.patch(values))

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getTransaction', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/transactions')
    }, 200)
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      amount: cur_item.data?.amount ?? '',
      due_date: cur_item.data?.due_date ?? '',
      is_paid: cur_item.data?.is_paid ?? false,
      description: cur_item.data?.description ?? '',
      payment_type: cur_item.data?.payment_type ?? '',
      patient: cur_item.data?.patient ?? '',
      moving_type: 'INCOME'
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required(),
      due_date: Yup.string().required(),
      payment_type: Yup.string().required()
    }),
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        title={cur_item?.data?.id ? 'Editar receita' : 'Nova receita'}
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={550}
        className="ant-modal-tabs-only"
        closable={false}
        footer={[
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading && !patients.isLoading &&
          <FormikProvider value={formikBag}>
            <Tabs defaultActiveKey={'1'}>
              <Tabs.TabPane tab="Detalhes" key="1">
                <Form layout="vertical">
                  <FMoney name="amount" label="Valor"/>
                  <FormGroup>
                    <FDate name="due_date" label="Vencimento"/>
                    <FSwitch name="is_paid" label="Foi recebido?"/>
                  </FormGroup>

                  <FormGroup>
                    <FInput name="description" label="Descrição"/>
                    <FSelect name="payment_type" label="Forma de Pagamento" options={PAY_SELECT}/>
                  </FormGroup>

                  <FSelect name="patient" label="Paciente" options={patients.data?.map(i => ({ value: i.id, label: i.name }))}/>
                </Form>
              </Tabs.TabPane>

              <Tabs.TabPane tab="Anexos" key="3">
                <Alert message="Esse arquivos estarão disponíveis no portal do paciente" type="info"/>
                <br/>
                <br/>

                <UploaderAttachment object_id={cur_item.data?.id} object_type="transaction"/>
              </Tabs.TabPane>
            </Tabs>
          </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
