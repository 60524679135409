import {MenuOutlined, ZoomInOutlined} from '@ant-design/icons'
import {Layout, PageHeader, Table, Tag} from 'antd'
import {ColumnsType} from 'antd/lib/table/interface'
import * as React from 'react'
import {useQuery} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Link} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {menuCollapseState} from '../../../state/atoms'
import {dateTimeFormat} from '../../../utils/formatters'
import {ATTENDANCE_STATUS_MP} from '../../constants'
import {qs} from '../../queries'
import {AttendanceModel} from '../../typings'

type Props = RouteConfigComponentProps

const status_color_map = {
  'PENDENT': 'orange',
  'FINISHED': 'green',
  'CANCELED': 'red'
}

export const ClientAttendanceListPage: React.FC<Props> = (props) => {
  const { isFetching, data, refetch } = useQuery('getClientAttendances', () => qs.attendances.client())
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const grid_columns: ColumnsType<AttendanceModel> = [
    {
      title: 'Paciente',
      width: 150,
      dataIndex: 'patient_name'
    },
    {
      title: 'Fisioterapeuta',
      width: 150,
      dataIndex: 'fisio_name'
    },
    {
      title: 'Data',
      dataIndex: 'date_start',
      width: 60,
      render: (value) => dateTimeFormat(value)
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      width: 20,
      render: (value) => (
        <Link to={`/attendances/${value}`}><ZoomInOutlined style={{ fontSize: '16px', marginRight: 5 }}/></Link>
      )
    }
  ]

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={'Atendimentos'}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <Layout.Content className="body-container">
        <Table columns={grid_columns} dataSource={data} rowKey="id" loading={isFetching}/>
        {renderRoutes(props.route?.routes)}
      </Layout.Content>
    </React.Fragment>
  )
}
