import * as React from 'react'
import {useState} from 'react'
import {Button, Col, Form, Input, Layout, PageHeader, Row, Table} from 'antd'
import {MenuOutlined, SnippetsOutlined, ZoomInOutlined} from '@ant-design/icons'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Link, useHistory} from 'react-router-dom'
import {useQuery} from 'react-query'
import {useRecoilState} from 'recoil'
import {menuCollapseState} from '../../../state/atoms'
import {qs} from '../../queries'
import {ColumnsType} from 'antd/lib/table/interface'
import {InterventionModel} from '../../typings'

const queryString = require('query-string')

const AdvancedSearchForm = React.memo(({ setQsQuery, refetch }: { setQsQuery: (data: any) => void, refetch: () => void }) => {
  const [filterForm] = Form.useForm()

  const onFinish = (values: any) => {
    let __qs = queryString.stringify(values, { arrayFormat: 'comma' })

    setQsQuery(__qs)
    setQsQuery(__qs)

    refetch()
  }

  return (
    <Form
      form={filterForm}
      name="advanced_search"
      className="advanced-search-form"
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={24}>
        <Col span={10}>
          <Form.Item name='filter{name.icontains}' label='Nome'>
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              filterForm.resetFields()
              setQsQuery('')
              setQsQuery('')
              refetch()
            }}
          >
            Resetar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  )
})

type Props = RouteConfigComponentProps

export const InterventionListPage: React.FC<Props> = (props) => {
  const [qs_query, setQsQuery] = useState('')
  const { isFetching, data, refetch } = useQuery('getInterventions', () => qs.interventions.all(qs_query))
  const history = useHistory()
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const grid_columns: ColumnsType<InterventionModel> = [
    {
      title: 'Nome',
      width: 400,
      dataIndex: 'name'
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      width: 20,
      render: (value) => (
        <Link to={`/interventions/${value}`}><ZoomInOutlined style={{ fontSize: '16px', marginRight: 5 }}/></Link>
      )
    }
  ]

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={'Intervenções'}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <AdvancedSearchForm refetch={refetch} setQsQuery={setQsQuery}/>

      <Layout.Content className="body-container">
        <Button type="primary" onClick={() => history.push('/interventions/new')}>Novo</Button>

        <Table columns={grid_columns} dataSource={data} rowKey='id' loading={isFetching}/>

        {renderRoutes(props.route?.routes)}
      </Layout.Content>
    </React.Fragment>
  )
}
