import {UploadOutlined} from '@ant-design/icons'
import {Button, Col, Form, Modal, Row, Tabs, Upload} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import * as Yup from 'yup'
import {FCheckbox, FDate, FInput, FInputNumber, FormGroup, FSelect, FSwitch, FTime} from '../../../components/form/FormInputs'
import {getPatientPrint} from '../../../utils/getPatientPrint'
import {qs} from '../../queries'
import {PatientModel} from '../../typings'

type PatientDetailPageProps = RouteConfigComponentProps

const PAY_SELECT = [
  { value: 'BOLETO', label: 'Boleto' },
  { value: 'WIRE', label: 'Transferência' },
  { value: 'CC', label: 'Cartão de crédito' },
  { value: 'CHECK', label: 'Cheque' },
  { value: 'MONEY', label: 'Dinheiro' }
]

const FECHAMENTO_SELECT = [
  { value: 'BY_DAY', label: 'Por dia do mês' },
  { value: 'BY_QTD', label: 'Por quantidade de sessões' }
]

type FormValues = { [K in keyof PatientModel]?: PatientModel[K] | '' } & { cur_contract: any }

export const PatientDetailPage: React.FC<PatientDetailPageProps> = () => {
  const fisios = useQuery('getFisios', () => qs.fisios.all())
  const me = useQuery('getMe', () => qs.users.getMe())

  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()
  const is_admin_or_assistant = me.data?.role === 'ADMIN' || me.data?.role === 'ASSISTANT'

  const cur_item = useQuery(['getPatient', item_id], () => qs.patients.get(item_id), {
    enabled: false,
    retry: false
  })

  const with_evolutions = useQuery(['getPatientWithEvolution', item_id], () => qs.patients.with_evolutions(item_id), {
    enabled: false,
    retry: false,
    onSuccess: (_data) => {
      const print_text = getPatientPrint(_data as any)
      const newWin = window.open('', 'printWindow')
      newWin!.document.body.innerHTML = print_text

      newWin!.document.body.onload = function () {
        // newWin!.print()
        setTimeout(() => {
          // newWin!.close()
        }, 100)
      }
    }
  })

  const create = useMutation(values => qs.patients.post(values), {
    onSuccess: () => handleCancel()
  })
  const update = useMutation(values => qs.patients.patch(values))

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getPatient', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/patients')
    }, 200)
  }

  const handlePrint = () => {
    with_evolutions.refetch()
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      fisio: Yup.string().required(),
      time: Yup.string().required()
    }),
    initialValues: {
      id: cur_item.data?.id ?? '',
      name: cur_item.data?.name ?? '',
      cpf: cur_item.data?.cpf ?? '',
      birthday: cur_item.data?.birthday ?? '',
      email: cur_item.data?.email ?? '',
      phone: cur_item.data?.phone ?? '',
      phone_emergency: cur_item.data?.phone_emergency ?? '',
      address: cur_item.data?.address ?? '',
      city: cur_item.data?.city ?? '',
      state: cur_item.data?.state ?? '',
      cep: cur_item.data?.cep ?? '',
      fisio: cur_item.data?.fisio_id ?? '',
      local: cur_item.data?.local ?? '',
      time: cur_item.data?.time ?? '',
      sun: cur_item.data?.sun ?? false,
      mon: cur_item.data?.mon ?? false,
      tue: cur_item.data?.tue ?? false,
      wed: cur_item.data?.wed ?? false,
      thu: cur_item.data?.thu ?? false,
      fri: cur_item.data?.fri ?? false,
      sat: cur_item.data?.sat ?? false,
      contract_active: cur_item.data?.contract_active ?? false,
      payment_type: cur_item.data?.payment_type ?? '',
      closing_type: cur_item.data?.closing_type ?? '',
      price_attendance: cur_item.data?.price_attendance ?? '',
      payment_deadline: cur_item.data?.payment_deadline ?? '',
      qtd_attendances: cur_item.data?.qtd_attendances ?? 0,
      month_day: cur_item.data?.month_day ?? 1,
      is_next_month: cur_item.data?.is_next_month ?? false,

      contractor_name: cur_item.data?.contractor_name ?? '',
      contractor_cpf: cur_item.data?.contractor_cpf ?? '',
      contractor_birthday: cur_item.data?.contractor_birthday ?? '',
      contractor_address: cur_item.data?.contractor_address ?? '',
      contractor_city: cur_item.data?.contractor_city ?? '',
      contractor_state: cur_item.data?.contractor_state ?? '',
      contractor_cep: cur_item.data?.contractor_cep ?? '',

      new_name: cur_item.data?.responsible_name ?? '',
      new_username: cur_item.data?.responsible_username ?? '',

      cur_contract: cur_item.data?.contract ?? ''
    },
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate({ ...values, ...{ form_data_keys: ['contract'] } })
      } else {
        create.mutate({ ...values, ...{ form_data_keys: ['contract'] } })
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={800}
        closable={false}
        className="ant-modal-tabs-only"
        footer={[
          <Button key="3" type="default" onClick={handlePrint} loading={with_evolutions.isLoading}>Imprimir Ficha de Evoluções</Button>,
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          is_admin_or_assistant && <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading &&
          <FormikProvider value={formikBag}>
            <Form layout="vertical">
              <Tabs defaultActiveKey={'1'}>
                <Tabs.TabPane tab="Dados Pessoais" key="1">
                  <Row gutter={48}>
                    <Col span={24}>
                      <FormGroup>
                        <FInput name="name" label="Nome"/>
                        <FInput name="cpf" label="CPF"/>
                        <FDate name="birthday" label="Data do Nascimento"/>
                      </FormGroup>
                      <FormGroup>
                        <FInput name="email" label="E-mail"/>
                        <FInput name="phone" label="Telefone Próprio"/>
                        <FInput name="phone_emergency" label="Telefone Emergência/Recado"/>
                      </FormGroup>

                      <FInput name="address" label="Endereço"/>
                      <FormGroup>
                        <FInput name="city" label="Cidade"/>
                        <FInput name="state" label="Estado"/>
                        <FInput name="cep" label="CEP"/>
                      </FormGroup>
                    </Col>
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Dados do Contratante" key="4">
                  <Row gutter={48}>
                    <Col span={24}>
                      <FormGroup>
                        <FInput name="contractor_name" label="Nome"/>
                        <FInput name="contractor_cpf" label="CPF"/>
                        <FDate name="contractor_birthday" label="Data do Nascimento"/>
                      </FormGroup>

                      <FInput name="contractor_address" label="Endereço"/>
                      <FormGroup>
                        <FInput name="contractor_city" label="Cidade"/>
                        <FInput name="contractor_state" label="Estado"/>
                        <FInput name="contractor_cep" label="CEP"/>
                      </FormGroup>
                    </Col>
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Atendimentos" key="2">
                  <FSelect name="fisio" label="Fisioterapeuta" options={fisios?.data?.map(i => ({ value: i.id, label: i.name }))}/>

                  <FormGroup>
                    <FInput name="local" label="Local"/>
                    <FTime name="time" label="Horário"/>
                  </FormGroup>

                  <label>Dias da semana</label>
                  <FormGroup>
                    <FCheckbox name="sun" label="Dom"/>
                    <FCheckbox name="mon" label="Seg"/>
                    <FCheckbox name="tue" label="Ter"/>
                    <FCheckbox name="wed" label="Qua"/>
                    <FCheckbox name="thu" label="Qui"/>
                    <FCheckbox name="fri" label="Sex"/>
                    <FCheckbox name="sat" label="Sáb"/>
                  </FormGroup>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Financeiro" key="3">
                  <FSwitch name="contract_active" label="Contrato Ativo"/>

                  <FormGroup>
                    <FSelect name="payment_type" label="Forma de Pagamento" options={PAY_SELECT}/>
                    <FSelect name="closing_type" label="Tipo de Fechamento" options={FECHAMENTO_SELECT}/>
                    <FInput name="price_attendance" label="Valor por Sessão" type="number"/>
                    <FInput name="payment_deadline" label="Prazo para Pagamento" type="number"/>
                  </FormGroup>

                  {formikBag.values.closing_type === 'BY_QTD' && <FInputNumber name="qtd_attendances" label="Quantidade de Sessões"/>}
                  {formikBag.values.closing_type === 'BY_DAY' &&
                    <FormGroup>
                      <FInputNumber name="month_day" label="Dia do Mês"/>
                      <FSwitch name="is_next_month" label="Mês subsequente"/>
                    </FormGroup>}

                  <Upload
                    beforeUpload={(file) => {
                      formikBag.setFieldValue('contract', file)
                      return false
                    }}
                    maxCount={1}
                    defaultFileList={formikBag.values.cur_contract ? [
                      {
                        uid: '1',
                        size: 0,
                        name: 'Contrato',
                        status: 'done',
                        type: 'done',
                        url: formikBag.values.cur_contract
                      }
                    ] : []}
                    onRemove={() => {
                      formikBag.setFieldValue('cur_contract', null)
                      formikBag.setFieldValue('contract', null)
                    }}
                  >
                    <Button icon={<UploadOutlined/>}>Click para enviar o Contrato</Button>
                  </Upload>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Acesso Responsável" key="access">
                  <FormGroup>
                    <FInput name="new_name" label="Nome"/>
                    <FInput name="new_username" label="Usuário"/>
                    <FInput name="new_password" label="Nova Senha" type="password"/>
                  </FormGroup>
                </Tabs.TabPane>
              </Tabs>
            </Form>
          </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
