import {Menu} from 'antd'
import * as React from 'react'
import {Link} from 'react-router-dom'
import {useQuery} from 'react-query'
import {qs} from '../../features/queries'

const { SubMenu } = Menu

export const RightMenu: React.FC = (props) => {
  const me = useQuery('getMe', () => qs.users.getMe())

  return (
    <div className="rightContainer">
      <Menu key="user" mode="horizontal">
        <SubMenu
          title={
            <React.Fragment>
              <span style={{ color: '#999', marginRight: 4 }}>Olá, </span>
              <span>{me.data?.name}</span>
            </React.Fragment>
          }
        >
          <Menu.Item key="SignOut">
            <Link to={`/login`}>Sair</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  )
}
