export const EQUIPMENT_STATUS = [
  { value: 'PENDENT', label: 'Pendente' },
  { value: 'MAINTENANCE', label: 'Manutenção Realizada' }
]

export const EQUIPMENT_STATUS_MP = {
  'PENDENT': 'Pendente',
  'MAINTENANCE': 'Manutenção Realizada'
}

export const ATTENDANCE_STATUS_MP = {
  'PENDENT': 'Pendente',
  'CANCELED': 'Cancelado',
  'FINISHED': 'Finalizado'
}

export const ROLES_SELECT = [
  { value: 'FISIO', label: 'Fisioterapeuta' },
  { value: 'ADMIN', label: 'Administrativo' },
  { value: 'ASSISTANT', label: 'Secretária' },
]

export const ROLES_SELECT_MP = {
  'FISIO': 'Fisioterapeuta',
  'ADMIN': 'Administrativo',
  'ASSISTANT': 'Secretária',
}
