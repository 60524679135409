import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import {Alert, Button, Col, Form, Modal, Row, Tabs} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useEffect, useRef} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import * as Yup from 'yup'
import {FDateTime, FInput, FInputNumber, FormGroup, FSelect} from '../../../components/form/FormInputs'
import {qs} from '../../queries'
import {AttendanceModel} from '../../typings'
import {EvolutionsTable} from '../components/EvolutionsTable'
import UploaderAttachment from '../components/UploaderAttachment'

type AttendanceDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof AttendanceModel]?: AttendanceModel[K] | '' } & { cur_fisio_sign: string, cur_patient_sign: string }

const TYPE_SELECT = [
  { value: 'SESSION', label: 'Sessão' },
  { value: 'EXAM', label: 'Avaliação' }
]

export const AttendanceDetailPage: React.FC<AttendanceDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()
  const fisio_canvas: any = useRef(null)
  const patient_canvas: any = useRef(null)

  const IS_TABLE_VIEW = history.location.pathname.indexOf('list') > -1
  const ALL_QUERY_KEY = IS_TABLE_VIEW ? 'getAttendances' : 'getAttendancesCalendar'
  const ROOT_PATH = IS_TABLE_VIEW ? '/attendances-list' : '/attendances'

  const fisios = useQuery('getFisios', () => qs.fisios.all())
  const patients = useQuery('getPatients', () => qs.patients.all())

  const cur_item = useQuery(['getAttendance', item_id], () => qs.attendances.get(item_id), {
    enabled: false,
    retry: false
  })

  const create = useMutation(values => qs.attendances.post(values), {
    onSuccess: (_values) => {
      if (!IS_TABLE_VIEW) {
        queryClient.invalidateQueries(ALL_QUERY_KEY)
      }

      queryClient.removeQueries(['getAttendance', item_id], { inactive: true, stale: true })

      history.push(`/attendances/${_values.data.id}`)
    }
  })
  const update = useMutation((values: any) => qs.attendances.patch(values), {
    onSuccess: () => {
      if (!IS_TABLE_VIEW) {
        queryClient.invalidateQueries(ALL_QUERY_KEY)
      }
    }
  })
  const destroy = useMutation((values: any) => qs.attendances.delete(values), {
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_QUERY_KEY)
      handleCancel()
    }
  })

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getAttendance', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push(ROOT_PATH)
    }, 200)
  }

  const handleChangeStatus = async (new_status: string) => {
    if (cur_item.data) {
      await update.mutateAsync({
        id: cur_item.data.id,
        status: new_status
      })

      await cur_item.refetch()
    }
  }

  const handleDestroy = () => {
    Modal.confirm({
      closable: true,
      maskClosable: true,
      icon: <ExclamationCircleOutlined/>,
      title: 'Tem certeza que gostaria de excluir esse atendimento?',
      content: 'Todos os registros vinculados tais como: intervenções e anexos também serão excluídos!',
      onOk() {
        destroy.mutate(cur_item.data)
      },
      onCancel() {

      }
    })
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      fisio: cur_item.data?.fisio_id ?? '',
      fisio_2: cur_item.data?.fisio_2_id ?? '',
      patient: cur_item.data?.patient_id ?? '',
      status: cur_item.data?.status ?? '',
      attendance_type: cur_item.data?.attendance_type ?? '',
      date_start: cur_item.data?.date_start ?? '',
      date_end: cur_item.data?.date_end ?? '',
      blood_pressure_sys: cur_item.data?.blood_pressure_sys ?? 0,
      blood_pressure_dias: cur_item.data?.blood_pressure_dias ?? 0,
      heart_rate: cur_item.data?.heart_rate ?? 0,
      observations: cur_item.data?.observations ?? '',

      cur_fisio_sign: '',
      cur_patient_sign: ''
    },
    validationSchema: Yup.object().shape({
      fisio: Yup.string().required(),
      patient: Yup.string().required(),
      attendance_type: Yup.string().required(),
      date_start: Yup.string().required(),
      date_end: Yup.string().required(),
      blood_pressure_sys: Yup.number().required(),
      blood_pressure_dias: Yup.number().required(),
      heart_rate: Yup.number().required()
    }),
    onSubmit: async (values: any) => {
      values = JSON.parse(JSON.stringify(values))

      values['form_data_keys'] = ['fisio_sign', 'patient_sign']

      if (!cur_item.data?.id) {
        values['status'] = 'PENDENT'
      }

      if (fisio_canvas.current && !fisio_canvas.current.isEmpty()) {
        let _url = fisio_canvas.current.toDataURL()
        let _blob = await (await fetch(_url)).blob()
        values['fisio_sign'] = new File([_blob], 'fisio_sign.png')
      }

      if (patient_canvas.current && !patient_canvas.current.isEmpty()) {
        let _url = patient_canvas.current.toDataURL()
        let _blob = await (await fetch(_url)).blob()
        values['patient_sign'] = new File([_blob], 'patient_sign.png')
      }

      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={800}
        closable={false}
        className="ant-modal-tabs-only"
        footer={[
          <div key="1" className="attendance-buttons">
            <Button key="4" type="default" onClick={handleCancel}>Fechar</Button>
            <Button key="5" type="dashed" danger onClick={handleDestroy} icon={<DeleteOutlined/>} loading={destroy.isLoading}>Excluir</Button>
            {cur_item.data?.id && cur_item.data?.status === 'PENDENT' &&
              <Button key="3" type="dashed" danger onClick={() => handleChangeStatus('CANCELED')}>Cancelar Atendimento</Button>}
            {cur_item.data?.id && cur_item.data?.status === 'PENDENT' &&
              <Button key="2" type="primary" onClick={() => handleChangeStatus('FINISHED')}>Finalizar Atendimento</Button>}
            {(!cur_item.data?.id || cur_item.data?.status === 'PENDENT') &&
              <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>}
          </div>
        ]}
      >
        {!cur_item.isLoading && !fisios.isLoading && !patients.isLoading &&
          <FormikProvider value={formikBag}>
            <Form layout="vertical">
              <Tabs defaultActiveKey={'1'}>
                <Tabs.TabPane tab="Detalhes" key="1">
                  <Row gutter={48}>
                    <Col span={24}>
                      <FormGroup>
                        <FSelect name="fisio" label="Fisioterapeuta" options={fisios.data?.map(i => ({ value: i.id, label: i.name }))}/>
                        <FSelect name="fisio_2" label="Fisioterapeuta 2" options={fisios.data?.map(i => ({ value: i.id, label: i.name }))}/>
                        <FSelect name="patient" label="Paciente" options={patients.data?.map(i => ({ value: i.id, label: i.name }))}/>
                      </FormGroup>

                      <FormGroup>
                        <FSelect name="attendance_type" label="Tipo" options={TYPE_SELECT}/>
                        <FDateTime name="date_start" label="Data do Atendimento"/>
                        <FDateTime name="date_end" label="Data de Encerramento"/>
                      </FormGroup>
                      <FormGroup>
                        <FInputNumber name="blood_pressure_sys" label="Pressão sistólica" type="number"/>
                        <FInputNumber name="blood_pressure_dias" label="Pressão diastólica" type="number"/>
                        <FInputNumber name="heart_rate" label="Frequência cardíaca (BPM)" type="number"/>
                      </FormGroup>
                      <FInput name="observations" label="Observações"/>
                    </Col>
                  </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Assinaturas" key="2">
                  <p>
                    Assinatura Fisioterapeuta
                    &nbsp;&nbsp;&nbsp;
                    <Button type="default" onClick={() => fisio_canvas.current?.clear()}>Limpar</Button>
                  </p>
                  <SignatureCanvas ref={fisio_canvas} penColor="black" canvasProps={{ width: 750, height: 250, className: 'sigCanvas' }}/>

                  <p>
                    Assinatura Paciente
                    &nbsp;&nbsp;&nbsp;
                    <Button type="default" onClick={() => patient_canvas.current?.clear()}>Limpar</Button>
                  </p>
                  <SignatureCanvas ref={patient_canvas} penColor="black" canvasProps={{ width: 750, height: 250, className: 'sigCanvas' }}/>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Anexos" key="3">
                  <UploaderAttachment object_id={cur_item.data?.id} object_type="attendance"/>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Intervenções" key="4">
                  {cur_item.data?.id && <EvolutionsTable item_id={cur_item.data?.id}/>}
                  {!cur_item.data?.id && <Alert message="Crie o atendimento primeiro" type="warning"/>}
                </Tabs.TabPane>
              </Tabs>
            </Form>
          </FormikProvider>}
        {renderRoutes(props.route?.routes)}
      </Modal>
    </React.Fragment>
  )
}
