import {ConfigProvider} from 'antd'
import ptBR from 'antd/es/locale/pt_BR'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import ReactDOM from 'react-dom'
import {QueryClientProvider} from 'react-query'

import {BrowserRouter as Router} from 'react-router-dom'
import {RecoilRoot} from 'recoil'
import {setLocale} from 'yup'

import App from './App'
import reportWebVitals from './reportWebVitals'
import queryClient from './services/queryClient'
import './styles/styles.less'
import './styles/styles.scss'

moment.locale('pt-br')

setLocale({
  mixed: {
    default: 'Não é válido',
    notType: 'Valor não é válido',
    required: 'Campo obrigatório'
  },
  string: {
    email: 'Email não é válido'
  },
  number: {
    min: 'Deve ser maior que ${min}'
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ptBR}>
      <RecoilRoot>
        <Router>
          <QueryClientProvider client={queryClient}>
            <App/>
            {/*<ReactQueryDevtools initialIsOpen={false}/>*/}
          </QueryClientProvider>
        </Router>
      </RecoilRoot>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
