import * as React from 'react'
import {FC, useEffect, useState} from 'react'
import {Layout} from 'antd'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Sidebar} from './Sidebar'
import {getLocalAuthToken} from '../../services/api'
import {useHistory} from 'react-router-dom'

type Props = RouteConfigComponentProps

export const AdminContainer: FC<Props> = (props) => {
  const history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      let _token = getLocalAuthToken()

      if (!_token || _token === undefined || _token === null) {
        history.push('/login')
      }
    })
  }, [history])

  return (
    <Layout>
      <Sidebar/>
      <Layout>
        {renderRoutes(props.route?.routes)}
      </Layout>
    </Layout>
  )
}
