import {MenuOutlined, ZoomInOutlined} from '@ant-design/icons'
import {Button, Checkbox, Col, Form, Input, Layout, PageHeader, Row, Table} from 'antd'
import {ColumnsType} from 'antd/lib/table/interface'
import moment from 'moment'
import * as React from 'react'
import {useState} from 'react'
import {useQuery} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Link, useHistory} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {menuCollapseState} from '../../../state/atoms'
import {qs} from '../../queries'
import {PatientModel} from '../../typings'

const queryString = require('query-string')

const AdvancedSearchForm = React.memo(({ setQsQuery, refetch }: { setQsQuery: (data: any) => void, refetch: () => void }) => {
  const [filterForm] = Form.useForm()

  const onFinish = (values: any) => {
    let __qs = queryString.stringify(values, { arrayFormat: 'comma' })

    setQsQuery(__qs)
    setQsQuery(__qs)

    refetch()
  }

  return (
    <Form
      form={filterForm}
      name="advanced_search"
      className="advanced-search-form"
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={24}>
        <Col span={10}>
          <Form.Item name={'filter{name.icontains}'} label="Nome">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name={'filter{cpf.icontains}'} label="CPF">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name={`filter{phone.icontains}`} label="Telefone">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item name={`filter{contract_active}`} valuePropName="checked" label="Contrato ativo">
            <Checkbox/>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              filterForm.resetFields()
              setQsQuery('')
              setQsQuery('')
              setTimeout(() => {
                refetch()
              }, 200)
            }}
          >
            Resetar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  )
})

type Props = RouteConfigComponentProps

export const PatientListPage: React.FC<Props> = (props) => {
  const [qs_query, setQsQuery] = useState('')
  const history = useHistory()
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const { isFetching, data, refetch } = useQuery('getPatients', () => qs.patients.all(qs_query))
  const me = useQuery('getMe', () => qs.users.getMe())

  const grid_columns: ColumnsType<PatientModel> = [
    {
      title: 'Nome',
      width: 200,
      render: (record: PatientModel) => {
        return <>
          {record.name}
          <br/> CPF: {record.cpf}
          <br/> Telefone: {record.phone}
        </>
      }
    },
    {
      title: 'Fisioterapeuta',
      width: 150,
      dataIndex: 'fisio_name'
    },
    {
      title: 'Agendamento',
      width: 150,
      key: 'schedule',
      render: (record: PatientModel) => {
        let week_days = []
        let week_day_key = {
          mon: 'Seg',
          tue: 'Ter',
          wed: 'Qua',
          thu: 'Qui',
          fri: 'Sex',
          sat: 'Sáb',
          sun: 'Dom'
        }

        for (let k of Object.keys(week_day_key)) {
          if (record[k]) {
            // @ts-ignore
            week_days.push(week_day_key[k])
          }
        }

        return (
          <>
            {record.time && moment(record.time, 'HH:mm:ss').format('HH:mm')} <br/>

            {week_days.join(', ')}
          </>
        )
      }
    },
    {
      title: 'Contrato Ativo',
      width: 70,
      dataIndex: 'contract_active',
      render: value => value ? 'Sim' : 'Não'
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      width: 20,
      render: (value) => (
        <Link to={`/patients/${value}`}><ZoomInOutlined style={{ fontSize: '16px', marginRight: 5 }}/></Link>
      )
    }
  ]

  const is_admin_or_assistant = me.data?.role === 'ADMIN' || me.data?.role === 'ASSISTANT'

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={'Pacientes'}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <AdvancedSearchForm refetch={refetch} setQsQuery={setQsQuery}/>

      <Layout.Content className="body-container">
        {is_admin_or_assistant && <Button type="primary" onClick={() => history.push('/patients/new')}>Novo</Button>}

        <Table columns={grid_columns} dataSource={data} rowKey="id" loading={isFetching}/>

        {renderRoutes(props.route?.routes)}
      </Layout.Content>
    </React.Fragment>
  )
}
