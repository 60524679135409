import * as React from 'react'
import {useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {qs} from '../../queries'
import {Button, Col, Popconfirm, Popover, Row, TimePicker} from 'antd'
import {DeleteOutlined, PlusOutlined, SaveOutlined} from '@ant-design/icons'
import moment from 'moment'

export const TimeSheetTab: React.FC<{ fisio_id: string | number }> = (props) => {
  const { fisio_id } = props

  const { data } = useQuery(['getSlots', fisio_id], () => qs.slots.all(`filter{fisio}=${fisio_id}`))
  const create = useMutation((values: any) => qs.slots.post(values))
  const destroy = useMutation((values: any) => qs.slots.delete(values))

  const [time, setTime] = useState('08:00:00')

  const days = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado'
  ]

  const weekday_map = {
    'Domingo': 'SUN',
    'Segunda': 'MON',
    'Terça': 'TUE',
    'Quarta': 'WED',
    'Quinta': 'THU',
    'Sexta': 'FRI',
    'Sábado': 'SAT'
  }

  const handleCreate = (day: string) => {
    let _data = {
      fisio: fisio_id,
      time_start: time,
      time_end: '00:00:00',
      week_day: weekday_map[day]
    }

    create.mutate(_data)
  }

  return (
    <React.Fragment>
      <Row gutter={48} className='time-slot-container'>
        {days.map(i => (
          <Col className='time-slot-column' key={i}>
            <p><strong>{i}</strong></p>

            {data?.filter(h => h.week_day === weekday_map[i]).map(o => (
              <p className='time-slot-column-row' key={o.id}>
                {moment(o.time_start, 'HH:mm:ss').format('HH:mm')}

                <Popconfirm
                  title="Tem certeza que gostaria de excluir esse horário?"
                  onConfirm={() => destroy.mutate(o)}
                  okText="Sim, excluir!"
                  cancelText="Não"
                >
                  <Button type="dashed" size='small' danger shape="circle" icon={<DeleteOutlined/>}/>
                </Popconfirm>
              </p>
            ))}

            <Popover
              placement="rightTop"
              title={'Criar novo horário'}
              trigger="click"
              destroyTooltipOnHide={true}
              content={
                <React.Fragment>
                  <TimePicker
                    defaultValue={time ? moment(time, 'HH:mm:ss') : undefined}
                    format='HH:mm'
                    onChange={(date, dateString) => {
                      let _date = date ? moment(date).format('HH:mm:ss') : ''
                      setTime(_date)
                    }}
                  />

                  <Button icon={<SaveOutlined/>} onClick={() => handleCreate(i)} loading={create.isLoading}>
                    Salvar
                  </Button>
                </React.Fragment>
              }
            >
              <Button icon={<PlusOutlined/>} block type='dashed'/>
            </Popover>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}