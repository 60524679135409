import axios from 'axios'
import queryClient from './queryClient'
// import {queryCache} from 'react-query'

export const SERVER_HOST = 'https://powerful-shore-56870.herokuapp.com'
// export const SERVER_HOST = 'http://localhost:8000'

export const apiAxios = axios.create({
  baseURL: `${SERVER_HOST}/api/`
})

apiAxios.interceptors.request.use(
  function (config) {
    const token = getLocalAuthToken()
    if (token) {
      config.headers['Authorization'] = `Token ${token}`
    }
    return config
  },
  function (error) {
    let status = error?.response?.status

    if (status === 401 || status === 403) {
      // history.push('/login')
      queryClient.clear()
    }

    return Promise.reject(error)
  }
)

export const openBlobApi = async (endpoint, method = 'get', data = null) => {
  return await apiAxios({
    method: 'get',
    url: endpoint,
    data: data,
    responseType: 'blob'
  })
    .then((res) => {
      const file = new Blob([res.data], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    })
    .catch(async (error) => {
      if (error.response) {
        try {
          const { data } = error.response

          const fileReader = new FileReader()
          const file = await new Promise((resolve, reject) => {
            fileReader.onerror = () => {
              fileReader.abort()
              reject(new Error('Problem parsing file'))
            }

            fileReader.onload = () => {
              resolve(fileReader.result)
            }

            fileReader.readAsText(data)
          })

          // @ts-ignore
          error = { response: { data: JSON.parse(file) } }
        } catch (readError) {
          error = 'Arquivo inválido'
        }
      } else {
        error = 'Arquivo inválido'
      }

      return Promise.reject(error)
    })
}

export const getLocalAuthToken = () => localStorage.getItem('revita:token')
export const setLocalAuthToken = (token) => localStorage.setItem('revita:token', token)