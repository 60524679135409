import {MenuOutlined} from '@ant-design/icons'
import {Button, PageHeader} from 'antd'
import * as React from 'react'
import {useMediaQuery} from 'react-responsive'
import {useHistory} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {menuCollapseState} from '../../state/atoms'

export const CustomPageHeader = (props: { title: string }) => {
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const history = useHistory()

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={props.title}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={isTabletOrMobile && <MenuOutlined/>}
        extra={<Button type="primary" onClick={() => history.push('/attendances/new')}>Novo</Button>}
        style={{
          maxHeight: '8vh'
        }}
      />
    </React.Fragment>
  )
}