import {AdminContainer} from '../components/layout'
import {ClientContainer} from '../components/layout/ClientContainer'
import {PublicContainer} from '../components/layout/PublicContainer'
import {AttendanceCalendarPage} from '../features/Attendance/pages/AttendanceCalendarPage'
import {AttendanceDetailPage} from '../features/Attendance/pages/AttendanceDetailPage'
import {AttendanceEvolutionDetailPage} from '../features/Attendance/pages/AttendanceInterventionDetailPage'
import {AttendanceListPage} from '../features/Attendance/pages/AttendanceListPage'
import {ClientAttendanceDetailPage} from '../features/Attendance/pages/ClientAttendanceDetailPage'
import {ClientAttendanceListPage} from '../features/Attendance/pages/ClientAttendanceListPage'
import {LoginPage} from '../features/Auth/pages/LoginPage'
import {ProfilePage} from '../features/Auth/pages/ProfilePage'
import {EquipmentDetailPage} from '../features/Equipment/pages/EquipmentDetailPage'
import {EquipmentListPage} from '../features/Equipment/pages/EquipmentListPage'
import {InterventionDetailPage} from '../features/Intervention/pages/InterventionDetailPage'
import {InterventionListPage} from '../features/Intervention/pages/InterventionListPage'
import {LoanDetailPage} from '../features/Loan/pages/LoanDetailPage'
import {LoanListPage} from '../features/Loan/pages/LoanListPage'
import {PatientListPage} from '../features/Patient'
import {PatientDetailPage} from '../features/Patient/pages/PatientDetailPage'
import {PhysioDetailPage} from '../features/Physio/pages/PhysioDetailPage'
import {PhysioListPage} from '../features/Physio/pages/PhysioListPage'
import {ClientIncomeDetailPage} from '../features/Transaction/pages/ClientIncomeDetailPage'
import {ClientTransactionListPage} from '../features/Transaction/pages/ClientTransactionListPage'
import {ExpenseDetailPage} from '../features/Transaction/pages/ExpenseDetailPage'
import {IncomeDetailPage} from '../features/Transaction/pages/IncomeDetailPage'
import {TransactionListPage} from '../features/Transaction/pages/TransactionListPage'

export const admin_routes = [
  {
    component: PublicContainer,
    routes: [
      {
        path: '/login',
        component: LoginPage
      },
      {
        component: AdminContainer,
        routes: [
          {
            path: '/',
            exact: true,
            component: AttendanceCalendarPage
          },
          {
            path: '/profile',
            component: ProfilePage
          },
          {
            path: '/patients',
            component: PatientListPage,
            routes: [
              {
                path: '/patients/:item_id',
                component: PatientDetailPage
              }
            ]
          },
          {
            path: '/fisios',
            component: PhysioListPage,
            routes: [
              {
                path: '/fisios/:item_id',
                component: PhysioDetailPage
              }
            ]
          },
          {
            path: '/interventions',
            component: InterventionListPage,
            routes: [
              {
                path: '/interventions/:item_id',
                component: InterventionDetailPage
              }
            ]
          },
          {
            path: '/loans',
            component: LoanListPage,
            routes: [
              {
                path: '/loans/:item_id',
                component: LoanDetailPage
              }
            ]
          },
          {
            path: '/equipments',
            component: EquipmentListPage,
            routes: [
              {
                path: '/equipments/:item_id',
                component: EquipmentDetailPage
              }
            ]
          },
          {
            path: '/attendances-list',
            component: AttendanceListPage,
            routes: [
              {
                path: '/attendances-list/:item_id',
                component: AttendanceDetailPage,

                routes: [
                  {
                    path: '/attendances-list/:attendance_id/evolutions/:item_id',
                    component: AttendanceEvolutionDetailPage
                  }
                ]
              }
            ]
          },
          {
            path: '/attendances',
            component: AttendanceCalendarPage,
            routes: [
              {
                path: '/attendances/:item_id',
                component: AttendanceDetailPage,

                routes: [
                  {
                    path: '/attendances/:attendance_id/evolutions/:item_id',
                    component: AttendanceEvolutionDetailPage
                  }
                ]
              }
            ]
          },
          {
            path: '/transactions',
            component: TransactionListPage,
            routes: [
              {
                path: '/transactions/income/:item_id',
                component: IncomeDetailPage
              },
              {
                path: '/transactions/expense/:item_id',
                component: ExpenseDetailPage
              }
            ]
          }
        ]
      }
    ]
  }
]

export const client_routes = [
  {
    component: PublicContainer,
    routes: [
      {
        path: '/login',
        component: LoginPage
      },
      {
        component: ClientContainer,
        routes: [
          {
            path: '/attendances',
            component: ClientAttendanceListPage,
            routes: [
              {
                path: '/attendances/:item_id',
                component: ClientAttendanceDetailPage
              }
            ]
          },
          {
            path: '/transactions',
            component: ClientTransactionListPage,
            routes: [
              {
                path: '/transactions/:item_id',
                component: ClientIncomeDetailPage
              }
            ]
          }
        ]
      }
    ]
  }
]

export const login_only_routes = [
  {
    component: PublicContainer,
    routes: [
      {
        path: '/login',
        component: LoginPage
      }
    ]
  }
]
