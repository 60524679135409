import {Checkbox, Col, DatePicker, DatePickerProps, Form, Input, InputNumber, Row, Select, Switch, TimePicker} from 'antd'
import {CheckboxProps} from 'antd/es/checkbox'
import {InputProps} from 'antd/es/input'
import {InputNumberProps} from 'antd/es/input-number'
import {SelectProps} from 'antd/es/select'
import {SwitchProps} from 'antd/lib/switch'
import {FastField, FieldProps, getIn} from 'formik'
import moment from 'moment'
import React, {FC} from 'react'
import {IMaskInput} from 'react-imask'

export const FormGroup: FC = ({ children }) => {
  if (Array.isArray(children)) {
    let span = 24 / children.length

    return (
      <Row gutter={16} align="middle">
        {children.map((i, index) => <Col key={index} md={span} sm={24} xs={24}>{i}</Col>)}
      </Row>
    )
  }

  return <Row><Col>{children}</Col></Row>
}

export const FInput: FC<InputProps & { label?: string }> = (props) => (
  <FastField {...props}>
    {({ field, form, ...rest }: FieldProps) => {
      let has_error = getIn(form.errors, field.name)
      let has_touched = getIn(form.touched, field.name)
      let error_msg = has_error && has_touched ? has_error : null

      return (
        <Form.Item label={props.label} validateStatus={has_error && has_touched ? 'error' : ''} help={error_msg} hasFeedback>
          <Input {...field} {...props}/>
        </Form.Item>
      )
    }}
  </FastField>
)

export const FCheckbox: FC<CheckboxProps & { label: string }> = (props) => (
  <FastField {...props}>
    {({ field, form }: FieldProps) => {
      let has_error = getIn(form.errors, field.name)
      let has_touched = getIn(form.touched, field.name)
      let error_msg = has_error && has_touched ? has_error : null

      return (
        <Form.Item validateStatus={has_error && has_touched ? 'error' : ''} help={error_msg} hasFeedback>
          <Checkbox {...field} {...props} checked={field.value}>{props.label}</Checkbox>
        </Form.Item>
      )
    }}
  </FastField>
)

export const FSwitch: FC<SwitchProps & { label: string, name: string }> = (props) => (
  <FastField {...props}>
    {({ field, form }: FieldProps) => {
      let has_error = getIn(form.errors, field.name)
      let has_touched = getIn(form.touched, field.name)
      let error_msg = has_error && has_touched ? has_error : null

      return (
        <>
          <label>{props.label}</label>
          <Form.Item validateStatus={has_error && has_touched ? 'error' : ''} help={error_msg} hasFeedback>
            <Switch checked={field.value} onChange={(value) => form.setFieldValue(field.name, value)}/>
          </Form.Item>
        </>
      )
    }}
  </FastField>
)

export const FInputNumber: FC<InputNumberProps & { label: string }> = (props) => (
  <FastField {...props}>
    {({ field, form }: FieldProps) => {
      let has_error = getIn(form.errors, field.name)
      let has_touched = getIn(form.touched, field.name)
      let error_msg = has_error && has_touched ? has_error : null

      return (
        <Form.Item label={props.label} validateStatus={has_error && has_touched ? 'error' : ''} help={error_msg} hasFeedback>
          <InputNumber  {...field} {...props} onChange={(value) => form.setFieldValue(field.name, value)}/>
        </Form.Item>
      )
    }}
  </FastField>
)

export const FMoney: FC<InputNumberProps & { label: string }> = (props) => (
  <FastField {...props}>
    {({ field, form }: FieldProps) => {
      let has_error = getIn(form.errors, field.name)
      let has_touched = getIn(form.touched, field.name)
      let error_msg = has_error && has_touched ? has_error : null

      return (
        <Form.Item label={props.label} validateStatus={has_error && has_touched ? 'error' : ''} help={error_msg} hasFeedback>
          <div className="ant-input-number">
            <IMaskInput
              className="ant-input-number-input"

              value={field.value ? `${field.value}` : ''}

              mask={Number}
              placeholder={props.placeholder}
              radix=","
              scale={2}
              thousandsSeparator="."
              padFractionalZeros={true}
              normalizeZeros={true}
              mapToRadix={['.']}
              signed={true}

              unmask={true} // true|false|'typed'
              // `value` if `unmask=false`,
              // `unmaskedValue` if `unmask=true`,
              // `typedValue` if `unmask='typed'`
              onAccept={(value, mask) => form.setFieldValue(field.name, value)}
              onBlur={() => form.setFieldTouched(field.name, true)}
            />
          </div>
        </Form.Item>
      )
    }}
  </FastField>
)

// export const FTextArea: FC<InputProps & TextAreaProps & { label: string, name: string }> = (props) => (
//   <FastField {...props} render={({field, form}: FieldProps) => {
//     let has_error = getIn(form.errors, field.name)
//     let has_touched = getIn(form.touched, field.name)
//     let error_msg = has_error && has_touched ? has_error : null
//
//     return (
//       <Form.Item label={props.label} validateStatus={has_error && has_touched ? 'error' : ''} help={error_msg} hasFeedback>
//         <Input.TextArea rows={3} {...field} {...props}/>
//       </Form.Item>
//     )
//   }}/>
// )

export const FDate: FC<DatePickerProps & { label: string }> = (props) => (
  <FastField {...props}>
    {({ field, form }: FieldProps) => {
      let has_error = getIn(form.errors, field.name)
      let has_touched = getIn(form.touched, field.name)
      let error_msg = has_error && has_touched ? has_error : null
      let defaultValue = field.value ? moment(field.value) : undefined

      return (
        <Form.Item label={props.label} validateStatus={has_error && has_touched ? 'error' : ''} help={error_msg} hasFeedback>
          <DatePicker
            {...props}
            defaultValue={defaultValue}
            value={defaultValue}
            format={'DD/MM/YYYY'}
            onChange={(date) => {
              let _date = date ? moment(date).format('YYYY-MM-DD') : ''
              form.setFieldValue(field.name, _date)
            }}
            onOpenChange={() => form.setFieldTouched(field.name)}
          />
        </Form.Item>
      )
    }}
  </FastField>
)

export const FTime: FC<DatePickerProps & { label: string }> = (props) => (
  <FastField {...props}>
    {({ field, form }: FieldProps) => {
      let has_error = getIn(form.errors, field.name)
      let has_touched = getIn(form.touched, field.name)
      let error_msg = has_error && has_touched ? has_error : null
      let defaultValue = field.value ? moment(field.value, 'HH:mm:ss') : undefined

      return (
        <Form.Item label={props.label} validateStatus={has_error && has_touched ? 'error' : ''} help={error_msg} hasFeedback>
          <TimePicker
            {...props}
            defaultValue={defaultValue}
            value={defaultValue}
            format="HH:mm"
            onChange={(date, dateString) => {
              let _date = date ? moment(date).format('HH:mm:ss') : ''
              form.setFieldValue(field.name, _date)
            }}
            onOpenChange={() => form.setFieldTouched(field.name)}
          />
        </Form.Item>
      )
    }}
  </FastField>
)

export const FDateTime: FC<DatePickerProps & { label: string }> = (props) => (
  <FastField {...props}>
    {({ field, form }: FieldProps) => {
      let has_error = getIn(form.errors, field.name)
      let has_touched = getIn(form.touched, field.name)
      let error_msg = has_error && has_touched ? has_error : null
      let defaultValue = field.value ? moment(field.value) : undefined

      return (
        <Form.Item label={props.label} validateStatus={has_error && has_touched ? 'error' : ''} help={error_msg} hasFeedback>
          <DatePicker
            defaultValue={defaultValue}
            value={defaultValue}
            format={'DD/MM/YYYY HH:mm'}
            showTime={{ minuteStep: 5, format: 'HH:mm' }}
            onChange={(date) => {
              let _date = date ? moment(date).toISOString() : ''
              form.setFieldValue(field.name, _date)
            }}
            onOpenChange={() => form.setFieldTouched(field.name)}
          />
        </Form.Item>
      )
    }}
  </FastField>
)

export const FSelect: FC<InputProps & SelectProps<{ value: any, label: any }> & { label: string }> = (props) => (
  <FastField {...props}>
    {({ field, form }: FieldProps) => {
      let has_error = getIn(form.errors, field.name)
      let defaultValue = field.value ? field.value : ''

      return (
        <Form.Item label={props.label} validateStatus={has_error ? 'error' : ''} help={has_error} hasFeedback>
          <Select
            showSearch
            allowClear
            defaultValue={defaultValue}
            value={defaultValue}
            onChange={(value: any) => form.setFieldValue(field.name, value)}
            filterOption={(input, option) => {
              // @ts-ignore
              return String(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
          >
            {props.options && props.options.map(
              i => <Select.Option key={i.value} value={i.value}>
                {i.label}
              </Select.Option>)}
          </Select>
        </Form.Item>
      )
    }}
  </FastField>
)
