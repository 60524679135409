import * as React from 'react'
import {FC} from 'react'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'

type Props = RouteConfigComponentProps

export const PublicContainer: FC<Props> = (props) => {
  return (
    <React.Fragment>
      {renderRoutes(props.route?.routes)}
    </React.Fragment>
  )
}
