import * as React from 'react'
import {useQuery} from 'react-query'
import create from 'zustand'
import {qs} from '../features/queries'
import {getLocalAuthToken} from '../services/api'

export const useMeQuery = () => {
  const me = useQuery('getMe', () => qs.users.getMe())
  const is_admin = me.data?.role === 'ADMIN'
  const is_assistant = me.data?.role === 'ASSISTANT'
  const is_fisio = me.data?.role === 'FISIO'
  const is_client = me.data?.role === 'CLIENT'
  const is_admin_or_assistant = is_admin || is_assistant
  const is_admin_or_assistant_or_is_fisio = is_admin || is_assistant || is_fisio

  return {
    me,
    is_admin,
    is_assistant,
    is_fisio,
    is_client,
    is_admin_or_assistant,
    is_admin_or_assistant_or_is_fisio
  }
}

const _token = !!getLocalAuthToken()
export const useAppStore = create(set => ({
  is_authenticated: _token,
  setAuthentication: (new_state: boolean) => set(state => ({ is_authenticated: new_state }))
}))