import {MenuOutlined, ZoomInOutlined} from '@ant-design/icons'
import {Button, Col, Form, Input, Layout, PageHeader, Row, Table} from 'antd'
import {ColumnsType} from 'antd/lib/table/interface'
import * as React from 'react'
import {useState} from 'react'
import {useQuery} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {Link, useHistory} from 'react-router-dom'
import {useRecoilState} from 'recoil'
import {useMeQuery} from '../../../components/hooks'
import {menuCollapseState} from '../../../state/atoms'
import {dateFormat} from '../../../utils/formatters'
import {EQUIPMENT_STATUS_MP} from '../../constants'
import {qs} from '../../queries'
import {EquipmentModel} from '../../typings'

const queryString = require('query-string')

const AdvancedSearchForm = React.memo(({ setQsQuery, refetch }: { setQsQuery: (data: any) => void, refetch: () => void }) => {
  const [filterForm] = Form.useForm()

  const onFinish = (values: any) => {
    let __qs = queryString.stringify(values, { arrayFormat: 'comma' })

    setQsQuery(__qs)
    setQsQuery(__qs)

    refetch()
  }

  return (
    <Form
      form={filterForm}
      name="advanced_search"
      className="advanced-search-form"
      onFinish={onFinish}
      layout={'vertical'}
    >
      <Row gutter={24}>
        <Col span={10}>
          <Form.Item name="filter{name.icontains}" label="Nome">
            <Input/>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit">
            Filtrar
          </Button>
          <Button
            style={{ margin: '0 8px' }}
            onClick={() => {
              filterForm.resetFields()
              setQsQuery('')
              setQsQuery('')
              refetch()
            }}
          >
            Resetar filtro
          </Button>
        </Col>
      </Row>
    </Form>
  )
})

type Props = RouteConfigComponentProps

export const EquipmentListPage: React.FC<Props> = (props) => {
  const [qs_query, setQsQuery] = useState('')
  const { isFetching, data, refetch } = useQuery('getEquipments', () => qs.equipments.all(qs_query))
  const history = useHistory()
  const [collapsed, setCollapsed] = useRecoilState(menuCollapseState)

  const { is_admin_or_assistant } = useMeQuery()

  let grid_columns: ColumnsType<EquipmentModel> = [
    {
      title: 'Nome',
      width: 400,
      dataIndex: 'name'
    },
    {
      title: 'Próxima manutenção',
      width: 200,
      dataIndex: 'next_maintenance',
      render: (value) => {
        return value && dateFormat(value)
      }
    },
    {
      title: 'Status',
      width: 200,
      dataIndex: 'status',
      render: (value) => {
        return value && EQUIPMENT_STATUS_MP[value]
      }
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'right',
      width: 20,
      render: (value) => (
        <Link to={`/equipments/${value}`}><ZoomInOutlined style={{ fontSize: '16px', marginRight: 5 }}/></Link>
      )
    }
  ]

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        title={'Equipamentos'}
        onBack={() => setCollapsed(!collapsed)}
        backIcon={<MenuOutlined/>}
      />

      <AdvancedSearchForm refetch={refetch} setQsQuery={setQsQuery}/>

      <Layout.Content className="body-container">
        {is_admin_or_assistant && <Button type="primary" onClick={() => history.push('/equipments/new')}>Novo</Button>}

        <Table columns={grid_columns} dataSource={data} rowKey="id" loading={isFetching}/>

        {renderRoutes(props.route?.routes)}
      </Layout.Content>

    </React.Fragment>
  )
}
