import {Button, Form, Modal} from 'antd'
import {FormikProvider, useFormik} from 'formik'
import * as React from 'react'
import {useEffect} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import RRuleGenerator from 'react-rrule-generator'
import * as Yup from 'yup'
import {FCheckbox, FInput, FormGroup, FSelect} from '../../../components/form/FormInputs'
import {EQUIPMENT_STATUS} from '../../constants'
import {qs} from '../../queries'
import {EquipmentModel} from '../../typings'

type EquipmentDetailPageProps = RouteConfigComponentProps

type FormValues = { [K in keyof EquipmentModel]?: EquipmentModel[K] | '' }

export const EquipmentDetailPage: React.FC<EquipmentDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getEquipment', item_id], () => qs.equipments.get(item_id), {
    enabled: false,
    retry: false
  })
  const create = useMutation(values => qs.equipments.post(values), {
    onSuccess: () => handleCancel()
  })
  const update = useMutation(values => qs.equipments.patch(values))

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  let mutating = create.isLoading || update.isLoading

  const handleCancel = () => {
    queryClient.removeQueries(['getEquipment', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/equipments')
    }, 200)
  }

  const formikBag = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      id: cur_item.data?.id ?? '',
      name: cur_item.data?.name ?? '',
      status: cur_item.data?.status ?? '',
      has_maintenance: cur_item.data?.has_maintenance ?? false,
      maintenance_rule: cur_item.data?.maintenance_rule ?? ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
    }),
    onSubmit: (values: any) => {
      if (cur_item.data?.id) {
        update.mutate(values)
      } else {
        create.mutate(values)
      }
    }
  })

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={600}
        closable={false}
        footer={[
          <Button key="2" type="default" onClick={handleCancel}>Fechar</Button>,
          <Button key="1" type="primary" onClick={formikBag.submitForm} loading={mutating}>Salvar</Button>
        ]}
      >
        {!cur_item.isLoading &&
          <FormikProvider value={formikBag}>
            <Form layout="vertical">
              <FormGroup>
                <FInput name="name" label="Nome"/>
                <FSelect name="status" label="Status" options={EQUIPMENT_STATUS}/>
              </FormGroup>

              <FCheckbox name="has_maintenance" label="Tem manutenção"/>

              <RRuleGenerator
                onChange={(rrule) => formikBag.setFieldValue('maintenance_rule', rrule)}
                value={formikBag.values.maintenance_rule}
                config={{
                  repeat: ['Yearly', 'Monthly', 'Weekly'],
                  yearly: 'on',
                  monthly: 'on',
                  end: ['Never'],
                  weekStartsOnSunday: true,
                  hideError: true,
                  hideEnd: true
                }}
                translations={{
                  locale: 'en-gb',
                  invalid_rrule: 'You provided an invalid RRule value to component. \'%{value}\' is not a correct RRule string.',
                  months: {
                    jan: 'Jan',
                    feb: 'Fev',
                    mar: 'Mar',
                    apr: 'Abr',
                    may: 'Mai',
                    jun: 'Jun',
                    jul: 'Jul',
                    aug: 'Ago',
                    sep: 'Set',
                    oct: 'Out',
                    nov: 'Nov',
                    dec: 'Dez'
                  },
                  days_short: {
                    mon: 'Seg',
                    tue: 'Ter',
                    wed: 'Qua',
                    thu: 'Qui',
                    fri: 'Sex',
                    sat: 'Sáb',
                    sun: 'Dom'
                  },
                  days: {
                    monday: 'Segunda',
                    tuesday: 'Terça',
                    wednesday: 'Quarta',
                    thursday: 'Quinta',
                    friday: 'Sexta',
                    saturday: 'Sábado',
                    sunday: 'Domingo',
                    day: 'Dia',
                    weekday: 'Dias de semana',
                    'weekend day': 'Final de semana'
                  },
                  numerals: {
                    first: 'First',
                    second: 'Second',
                    third: 'Third',
                    fourth: 'Fourth',
                    last: 'Last'
                  },
                  start: {
                    label: 'Start',
                    tooltip: 'Datetime picker for start on date'
                  },
                  repeat: {
                    label: 'Agendamento Manutenção',
                    yearly: {
                      label: 'Anual',
                      on: 'mês',
                      on_the: 'on the',
                      of: 'of'
                    },
                    monthly: {
                      label: 'Mensal',
                      every: 'a cada',
                      months: 'mês(es)',
                      on_day: 'no dia',
                      on_the: 'on the'
                    },
                    weekly: {
                      label: 'Semanal',
                      every: 'a cada',
                      weeks: 'semana(s)'
                    },
                    daily: {
                      label: 'Daily',
                      every: 'every',
                      days: 'day(s)'
                    },
                    hourly: {
                      label: 'Hourly',
                      every: 'every',
                      hours: 'hour(s)'
                    }
                  },
                  end: {
                    label: 'Término',
                    tooltip: 'Datetime picker for end on date',
                    never: 'Nunca',
                    after: 'Depois',
                    on_date: 'Data específica',
                    executions: 'executions.'
                  }
                }}
              />
            </Form>
          </FormikProvider>}
      </Modal>
    </React.Fragment>
  )
}
