import {Button, Col, List, Modal, Row, Tabs} from 'antd'
import * as React from 'react'
import {useEffect} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {renderRoutes, RouteConfigComponentProps} from 'react-router-config'
import {useHistory, useParams} from 'react-router-dom'
import {FormGroup} from '../../../components/form/FormInputs'
import {dateTimeFormat} from '../../../utils/formatters'
import {qs} from '../../queries'
import {ELASTIC_SELECT, KEEP_REST_SELECT, TIME_SELECT, WIGHT_SELECT} from './AttendanceInterventionDetailPage'

type AttendanceDetailPageProps = RouteConfigComponentProps

const TYPE_ENUM = {
  SESSION: 'Sessão',
  EXAM: 'Avaliação'
}

export const TIME_SELECT_ENUM = TIME_SELECT.reduce((prev, curr) => {
  prev[curr.value] = curr.label
  return prev
}, {})

export const KEEP_REST_ENUM = KEEP_REST_SELECT.reduce((prev, curr) => {
  prev[curr.value] = curr.label
  return prev
}, {})

export const WIGHT_ENUM = WIGHT_SELECT.reduce((prev, curr) => {
  prev[curr.value] = curr.label
  return prev
}, {})

export const ELASTIC_ENUM = ELASTIC_SELECT.reduce((prev, curr) => {
  prev[curr.value] = curr.label
  return prev
}, {})

export const ClientAttendanceDetailPage: React.FC<AttendanceDetailPageProps> = (props) => {
  const { item_id } = useParams<{ item_id: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const cur_item = useQuery(['getAttendance', item_id], () => qs.attendances.client_details(item_id), {
    enabled: false,
    retry: false
  })

  useEffect(() => {
    cur_item.refetch()
  }, [item_id])

  const handleCancel = () => {
    queryClient.removeQueries(['getAttendance', item_id], { inactive: true, stale: true })

    setTimeout(() => {
      history.push('/attendances')
    }, 200)
  }

  return (
    <React.Fragment>
      <Modal
        visible={true}
        maskClosable
        destroyOnClose
        onCancel={handleCancel}
        width={800}
        closable={false}
        className="ant-modal-tabs-only"
        footer={[
          <div key="1" className="attendance-buttons">
            <Button key="4" type="default" onClick={handleCancel}>Fechar</Button>
          </div>
        ]}
      >
        {cur_item.data?.id &&
          <Tabs defaultActiveKey={'1'}>
            <Tabs.TabPane tab="Detalhes" key="1">
              <Row gutter={48}>
                <Col span={24}>
                  <FormGroup>
                    <p><b>Fisioterapeuta:</b> {cur_item.data?.fisio_name}</p>
                  </FormGroup>

                  <FormGroup>
                    <p><b>Data do Atendimento:</b> {dateTimeFormat(cur_item.data?.date_start)}</p>
                    <p><b>Tipo:</b> {TYPE_ENUM[cur_item.data!.attendance_type]}</p>
                  </FormGroup>
                  <br/>
                  <p><b>Pressão sistólica:</b> {cur_item.data?.blood_pressure_sys}</p>
                  <p><b>Pressão diastólica:</b> {cur_item.data?.blood_pressure_dias}</p>
                  <p><b>Frequência cardíaca (BPM):</b> {cur_item.data?.heart_rate}</p>
                  <br/>
                  <p><b>Observações:</b> {cur_item.data?.observations}</p>
                </Col>
              </Row>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Intervenções" key="4">
              <List
                loading={cur_item.isLoading}
                itemLayout="horizontal"
                dataSource={cur_item.data?.evolutions ?? []}
                rowKey="id"
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.intervention_name}
                      description={
                        <>
                          {!!item.series && <>Series: {item.series} <br/></>}

                          {!!item.repeats && <>Repetições: {item.repeats} <br/></>}

                          {!!item.time && <>Fazer por: {TIME_SELECT_ENUM[item.time]} <br/></>}

                          {!!item.keep_by && <>Manter por: {KEEP_REST_ENUM[item.keep_by]} <br/></>}

                          {!!item.rest && <>Descanso: {KEEP_REST_ENUM[item.rest]} <br/></>}

                          {!!item.weight && <>Carga (Kg): {WIGHT_ENUM[item.weight]} <br/></>}

                          {!!item.elastic && <>Elástico: {ELASTIC_ENUM[item.elastic]} <br/></>}

                          {!!item.observations && <>Observações: {item.observations} <br/></>}
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </Tabs.TabPane>
          </Tabs>}
        {renderRoutes(props.route?.routes)}
      </Modal>
    </React.Fragment>
  )
}
